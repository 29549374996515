import Splide from "@splidejs/splide";

let carousel = document.querySelector('.js-box-carousel');
if (carousel !== null) {
    new Splide(carousel, {
        type: 'carousel',
        arrows: true,
        pagination: false,
        autoplay: false,
        // autoWidth: true,
        perMove: 1,
        perPage: 3,
        gap: '50px',
        breakpoints: {
            1150: {
                perPage: 2,
                gap: '30px'
            },
        }
    }).mount();
}